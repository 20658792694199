<template>
  <div>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >¿Seguro que quieres eliminarlo?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteEPGGene"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title>
        <v-btn
          @click="$router.go(-1)"
          class="mx-2"
          fab
          dark
          small
          color="primary"
        >
          <v-icon dark>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        {{ epgData.epg.symbol }}
        <v-spacer></v-spacer>
        <v-btn @click="saveEPGGene" class="mx-2" fab dark small color="green">
          <v-icon dark>
            mdi-content-save
          </v-icon>
        </v-btn>
        <v-btn
          @click="dialogDelete = true"
          class="mx-2"
          fab
          dark
          small
          color="red"
        >
          <v-icon dark>
            mdi-delete
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs
          v-model="activeSection"
          background-color="deep-purple accent-4"
          center-active
          dark
        >
          <v-tab
            v-for="section in epgGeneSections"
            :key="section"
            @change="tabChanged"
          >
            {{ section.replaceAll("_", " ") }}
          </v-tab>
        </v-tabs>
        <MarkdownField v-model="epgData.epg[epgGeneSections[activeSection]]" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Strapi from "@/services/Strapi.js";
import MarkdownField from "@/components/mylogy/MarkdownField.vue";
export default {
  props: ["geneId"],
  components: {
    MarkdownField
  },
  data() {
    return {
      dialogDelete: false,
      activeSection: 0,
      epgGeneSections: [
        "rna",
        "protein",
        "function",
        "related_diseases",
        "related_drugs",
        "animal_models",
        "allelic_variants",
        "allele_nomenclature",
        "selected_snps",
        "evolution",
        "genomics_and_pharmacogenomics",
        "drug_gene_interactions",
        "authors",
        "references"
      ],
      epgData: {
        hide: null,
        epg: {
          _id: null,
          symbol: null,
          name: null,
          allele_nomenclature: null,
          alternative_names: null,
          alternative_symbols: null,
          codes: null,
          gene: null,
          rna: null,
          protein: null,
          function: null,
          related_diseases: null,
          related_drugs: null,
          animal_models: null,
          allelic_variants: null,
          selected_snps: null,
          evolution: null,
          genomics_and_pharmacogenomics: null,
          drug_gene_interactions: null,
          authors: null,
          references: null,
          toc: null,
          wp_id: null,
          matched: null,
          kb_gene: null,
          id: null
        }
      }
    };
  },
  computed: {},
  mounted() {
    let self = this;
    Strapi.get(`/genes/${self.geneId}/kb`).then(res => {
      if (res.data.epg) {
        self.epgData = res.data;
      }
      self.epgData.epg.kb_gene = self.geneId;
    });
  },
  methods: {
    getEPGGene() {},
    saveEPGGene() {
      let self = this;
      if (self.epgData.epg.id) {
        self.updateEPGGene();
      } else {
        self.createEPGGene();
      }
    },
    createEPGGene() {
      let self = this;
      Strapi.post(`/genes`, this.epgData.epg).then(res => {
        self.epgData.epg = res.data;
        self.$alertify.success("Creado correctamente");
      });
    },
    updateEPGGene() {
      let self = this;
      Strapi.put(`/genes/${this.epgData.epg.id}`, this.epgData.epg).then(
        res => {
          self.epgData.epg = res.data;
          self.$alertify.success("Actualizado correctamente");
        }
      );
    },
    deleteEPGGene() {
      let self = this;
      Strapi.delete(`/genes/${this.epgData.epg.id}`).then(() => {
        self.$alertify.success("Eliminado correctamente");
        self.$router.go(-1);
      });
    },
    tabChanged(tab) {
      console.log(tab);
    }
  }
};
</script>
<style scoped></style>
