<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <pmd v-model="content" :showToolbar="true" :showTextarea="true"></pmd>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["value"],
  data: () => {
    return {
      content: ""
    };
  },
  watch: {
    value(newVal) {
      if (this.content != newVal) {
        this.content = newVal;
      }
    },
    content(newContent) {
      if (newContent != this.value) {
        this.$emit("input", newContent);
      }
    }
  }
};
</script>
